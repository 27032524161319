/*.form-inline {*/
/*	width: 100%;*/
/*}*/

/*.form-group {*/
/*	width: 100%;*/
/*}*/

/*.input-group {*/
/*	width: 100% !important;*/
/*}*/

/*.form-control {*/
/*	width: 50% !important;*/
/*}*/

/*span.input-group-addon {*/
/*	width: 50px !important;*/
/*}*/



.JobListRow table tr {
font-size: 1.5rem;
}
.JobListRow table tr th {
    border: 0;
    padding: 0 5px;
    background-color: #1b67d3;
    color: #fff;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
}
.JobListRow table {
    border: 0;
    border-radius: 0; background-color:#fff;
}
.JobListRow table tbody tr {
    border-bottom: 2px solid #e4e4e4; 
}
.JobListRow table tr td {
	border: 0;
	vertical-align: middle;
	text-align: center;
	padding: 5px 10px;
}
.JobListRow table tr {
    height: 65px;
}
.JobListRow table tbody tr svg {
    width: 30px !important;
    height: 30px;
    color: #1b67d3;
}
.JobListRow table tr td div + span {
    font-size: 20px;
    color: #1a1a1a;
    vertical-align: middle !important;
    text-align: center !important;
    padding-left: 10px;
}
.JobListRow table tr td a {
    font-size: 20px;
    color: #1a1a1a;
}
.jobTitlesec.col {
    flex: 0 0 100%;
	padding-left:30px;
}
.text-right.jobRightsec.col {
    flex: 0 0 100%;
    padding-bottom: 10px;
	padding-right:40px;
}
.jobTitlesec h1 {
    font-size: 40px;
    color: #1a1a1a;
    font-weight: 300;
    font-style: normal;
}
.jobRightsec button.btn {
	background-color: #1b68d5;
	font-weight: bold;
	font-size: 18px;
	line-height: normal;
	border-radius: 40px;
	padding: 8px 30px 10px;
	border-color: #1b68d5;
}
.JobListRow table tr td .rounded-circle-ancher img {
    border-radius: 0 !important;
    height: 50px;
    object-fit: cover;
    width: 80px !important;
}
.JobListRow table tr {
    background: transparent !important;
}

.JobListRow table tr td span {
    font-size: 20px;
}
.mobile-view-list.dropdown {
    display: none;
}
.JobListRow table .list-m-view {
    display: none;
}
td.mobile-view-td {
    display: none;
}
.modal-body audio {
    border: 3px solid #e5e5e5;
    border-radius: 40px;
    width: 80%;
    margin: 0 auto;
    display: block;
}
.modal-body video {
    width: 100%;
    border: 3px solid #e5e5e5;
    border-radius: 10px;
    margin: 10px 0;
}
.modal-body .popvideo-sect > div {
    width: 80% !important;
    display: block;
    margin: 0 auto;
    object-fit: cover;
}
@media (max-width:767px) {
	td.mobile-view-td {
		display: table-cell;
	}

	.JobListRow table {
		width: 740px;
		display: block;
	}
}
.JobListRow {
    overflow-x: auto;
}
.JobListRow table tbody tr svg {
    width: 22px !important;
	height:22px
}
.text-right.jobRightsec.col {
    text-align: right !important;
    padding-right: 15px;
}
.jobTitlesec.col {
    padding-left: 15px;
    text-align: left;
}
div#root {
    display: block;
}
.jobRightsec button.btn {
    font-size: 22px;
    padding: 10px 30px;
}
.JobListRow table tr td img {
    width: 100%;
    max-width: 50px;
}
.mobile-view-list.dropdown {
    display: inline-block;
}
.JobListRow table .list-m-view {
  display: table-cell;
}
.create-job-modal .modal-header .mb-3 {
    margin-bottom: 0 !important;
    color: #1b67d3;
    font-weight: bold;
    letter-spacing: 0;
    font-size: 30px;
}
.create-job-modal .modal-header svg {
    width: 20px;
    color: #1b67d3;
}
.create-job-modal .modal-header .close {
	margin: 0;
	padding: 0;
	color: #fff;
	border-radius: 50% !important;
	height: 35px;
	width: 35px;
	background-color: #1b67d3;
	opacity: 1;
	display: inline-block;
	line-height: normal;
	position: absolute;
	right: 10px;
}
.create-job-modal .modal-header .close span {
    line-height: 30px;
    font-size: 30px;
    display: inline-block;
    position: relative;
    top: -2px;
}
.create-job-modal .modal-body {
	background-color: #f7f7f7;
	margin: 50px;
	padding: 40px;
}
.create-job-modal .modal-body .container {
	background-color: transparent;
}
.create-job-modal .modal-header {
    border-bottom: 0;
    border-radius: 0;
}
.create-job-modal .modal-footer {
    display: none !important;
}
.create-job-modal .modal-body form .form-group input[type="text"]:focus, .create-job-modal .modal-body form .form-group textarea:focus {
	color:#000;
}
.create-job-modal .modal-body form .form-group input[type="text"] {
	height: 50px;
	border: 1px solid #cbcccd;
	padding: 0 20px;
	color: #9e9e9e;
	font-size: 17px;
	border-radius: 6px;
	box-shadow: 0 1px 3px #a2a2a4;
}
.create-job-modal .modal-body form .form-group textarea {
	height: 110px;
	border: 1px solid #cbcccd;
	padding:10px 20px;
	color: #9e9e9e;
	font-size: 17px;
	border-radius: 6px;
	box-shadow: 0 1px 3px #a2a2a4;
}
.create-job-modal .modal-body form .form-group input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
 	color: #9e9e9e; opacity:1;
}
.create-job-modal .modal-body form .form-group input::-moz-placeholder { /* Firefox 19+ */
 	color: #9e9e9e; opacity:1;
}
.create-job-modal .modal-body form .form-group input:-ms-input-placeholder { /* IE 10+ */
 	color: #9e9e9e; opacity:1;
}
.create-job-modal .modal-body form .form-group input:-moz-placeholder { /* Firefox 18- */
 	color: #9e9e9e; opacity:1;
}
.create-job-modal .modal-body form .form-group {
    margin-bottom: 25px;
}
.create-job-modal .modal-body form button.btn {
	background-color: #1b67d3 !important;
	opacity: 1;
	border: 0;
	font-weight: 700;
	font-size: 22px;
	border-radius: 30px;
	padding: 5px 20px 8px;
	line-height: normal;
	width: 220px;
	margin: 30px auto 0;
	cursor: pointer;
	box-shadow: inherit !important;
}
.create-job-modal .fileUploader .fileContainer {
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 1px 3px #a2a2a4;
	border: 1px solid #cbcccd;
	margin-bottom:20px;
}
.create-job-modal .fileUploader .fileContainer .chooseFileButton {
	background-color: #1b67d3;
}
.geoapify-autocomplete-input {
	position: relative;
	width: 100%;
}
.geoapify-autocomplete-items div {
	cursor: pointer;
	/*line-height: 200%;*/
	padding: 10px;
	font-family: Roboto,Helvetica Neue,sans-serif;

}
.geoapify-close-button.visible {
	display: flex;
}
.geoapify-close-button {
	color: rgba(0,0,0,.4);
	cursor: pointer;
}
.geoapify-close-button {
	position: absolute;
	right: 5px;
	top: 0;
	height: 100%;
	display: none;
	align-items: center;
}
.geoapify-autocomplete-items .active {

}
.geoapify-autocomplete-item {
	color: blue;
}
.geoapify-autocomplete-item span .main-part {
	font-weight: bolder;
	padding: 5px;
}
.geoapify-autocomplete-item .icon {
	display: inline-block;
	width: 40px;
	height: 24px;
	color: #aaa;
}

.upper-image-main > img {
	border-radius: 5px;
	width: 100%;
	max-width: 300px !important;
	height: auto;
}
.create-job-modal .modal-body form .upper-image-main {
	margin-bottom:20px;
}
.create-job-modal .modal-body form .upper-image-main > button.btn {
	margin-left: 20px;
	margin-top: 0;
	font-size: 18px;
	width: auto;
	padding: 5px 30px 8px;
}
.create-job-modal .modal-body form .form-group input#name {
    width: 100%;
}
.create-job-modal {
    padding-left: 0 !important;
}


@media (max-width:991px) {
	.create-job-modal .modal-dialog {
		max-width: 98%;
	}

	.create-job-modal .modal-body {
		margin: 10px;
		padding: 10px;
	}

	@media (max-width: 767px) {
		td.mobile-view-td {
			display: table-cell;
		}
	}
	.JobListRow table {
		width: 100%;
		display: block;
	}

	.JobListRow {
		overflow-x: auto;
	}

	.JobListRow table tbody tr svg {
		width: 22px !important;
		height: 22px
	}

	.text-right.jobRightsec.col {
		/* text-align: center !important; */
		padding-right: 15px;
	}

	.jobTitlesec.col {
		padding-left: 15px;
		/* text-align: center; */
	}

	div#root {
		display: block;
	}

	.jobRightsec button.btn {
		font-size: 16px;
		padding: 8px 20px;
	}

	.JobListRow table tr td img {
		width: 100%;
		max-width: 50px;
	}

	.mobile-view-list.dropdown {
		display: inline-block;
	}

	.JobListRow table .list-m-view {
		display: table-cell;
	}

	.JobListRow table .list-m-hide {
		display: none;
	}

	.mobile-view-list button.btn::after {
		display: none;
	}

	.mobile-view-list button.btn {
		border-radius: inherit;
		padding: 0;
		background-color: transparent !important;
		border: 0 !important;
		box-shadow: none !important;
	}

	.mobile-view-list button.btn span {
		height: 4px;
		width: 35px;
		display: block;
		background-color: #1b67d3;
		margin: 6px 0;
	}

	.mobile-view-list .dropdown-menu {
		left: 0 !important;
		right: 0 !important;
		width: 100%;
		float: none;
		min-width: 40px;
		max-width: 100%;
		margin: 0 auto !important;
		padding: 0;
		border-color: #1b67d3;
	}

	.mobile-view-list .dropdown-menu .dropdown-item td {
		padding: 0;
	}

	.mobile-view-list .dropdown-menu .dropdown-item {
		white-space: normal;
		width: auto;
		padding: 8px;
		background-color: transparent !important;
		border-bottom: 1px solid #1b67d3;
	}

	.mobile-view-list .dropdown-menu, .mobile-view-list .dropdown-menu.show {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}

	.JobListRow table tr th, .JobListRow table tr td span, .JobListRow table tr td a {
		font-size: 16px;
	}

	.JobListRow table tr {
		height: 60px;
	}

	.JobListRow table tr td div + span {
		font-size: 16px !important;
	}

	.JobListRow table tr th {
		padding: 0 10px;
	}

	/****/
	.create-job-modal .modal-body .container {
		background-color: transparent;
		max-width: 100%;
		overflow: inherit;
	}

	.create-job-modal .modal-body form .form-group input#name {
		max-width: 100%;
	}

	.create-job-modal .modal-body form .row .col {
		width: 100%;
		flex: 0 0 100%;
	}

	.create-job-modal .modal-body form .form-group {
		margin-bottom: 15px;
	}

	.create-job-modal .modal-body form button.btn {
		font-size: 20px;
		margin: 15px auto 0;
	}

	.upper-image-main > img {
		max-width: 150px !important;
		display: block;
		margin: 0 auto;
	}

	.create-job-modal .fileUploader .fileContainer {
		margin-bottom: 0;
	}

	.create-job-modal .modal-header .mb-3 {
		font-size: 25px;
	}

	.create-job-modal .modal-header .close {
		height: 30px;
		width: 30px;
	}

	.create-job-modal .modal-header .close span {
		line-height: 28px;
		font-size: 28px;
		top: -3px;
	}

	.create-job-modal .modal-header svg {
		width: 18px;
	}

	.create-job-modal .modal-body form .upper-image-main > button.btn {
		margin-left: auto;
		margin-top: 10px;
		margin-right: auto;
		display: block;
	}

	.fileUploader {
		margin-bottom: 10px;
	}

	.JobListRow.w-100 {
		overflow: inherit;
	}
	
	

	
}
