.fa-custom-counter {
    transform: scale(0.75) translateX(70%) translateY(-70%);
}

.tape-annotations {

}

.text-note {
    position: absolute;
    zIndex: 2;
    left: 20px;
    top: -15px;
}
.image-note {
    position: absolute;
    zIndex: 2;
    left: 45px;
    top: 12px;
}
.video-note {
    position: absolute;
    zIndex: 2;
    left: 45px;
    top: 40px;
}
.audio-note {
    position: absolute;
    zIndex: 2;
    left: 15px;
    top: 65px;
}


.job-detail-modal .modal-body {
    background-color: #f7f7f7;
    padding: 20px 0px 50px;
}
.job-detail-modal .modal-body .container {
	background-color: transparent;
	width: 100%;
	max-width: 100%;
}
.job-detail-modal .close {
	padding: 0 !important;
	width: 40px;
	height: 40px;
	background-color: #1b67d3;
	border-radius: 50%;
	opacity: 1 !important;
	text-shadow: inherit;
	color: #fff !important;
	line-height: 0;
	float: none;
	position: absolute;
	right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 !important;
	top: 14px;
}
.job-detail-modal .close span {
	line-height: normal;
	font-size: 35px;
	margin-top: -8px;
	margin-left: 0;
}

.modal-reply-main {
	background-color: #fff;
	border-bottom: 1px solid #1b67d3;
	padding: 10px 30px;
	margin-right: 40px;
}
.modal-title-main {
	  background-color: #fff; 
	      padding: 0px 40px !important;
}
.modal-title-main .modal-inner {
    background-color: #fff;
    border-bottom: 1px solid #1b67d3;
    padding: 10px 0px;
}
.modal-title-main img, .modal-reply-main img {
    width: 40px !important;
    height: 40px !important;
}
.modal-title-main .sb-avatar, .modal-reply-main .sb-avatar {
    width: 40px !important;
    height: 40px !important; margin-right:20px;
} 
.modal-title-main span time, .modal-reply-main span time {
    color: #1b67d3;
    font-size: 18px;
    font-weight: 300;
}
.modal-title-main .float-right svg, .modal-reply-main .float-right svg {
	width: 30px;
	height: 30px;
	margin-left: 15px;
	color: #1b67d3;
}
.modal-title-main .float-right span, .modal-reply-main .float-right span {
    padding: 0 !important;
    width: auto;
    display: inline-block;
}
.modal-image-main {
    text-align: center;
    padding: 20px 0;
}
.modal-image-main .img-thumbnail {
    padding: 0;
    border-radius: 0;
    border: 0;
    margin: 10px 20px;
}
.job-detail-modal .mt-3 {
    margin-top: 0 !important;
}
.modal-title-content, .modal-reply-text {
    padding: 10px 0 10px !important;
    text-align: left;
}
.modal-title-content {
    padding-left:40px !important;
   padding-right:40px !important;
}
.modal-reply-text.col {padding-left:0; padding-right:0;}
.modal-title-content span, .modal-reply-text span {
    font-size: 18px;
    word-wrap: break-word;
    line-height: 18px;
    color: #000;
}
.job-detail-modal .invisible {
    display: none;
}
.job-detail-modal .modal-title p {
    margin: 0;
}
.modal-title-main > .px-2, .modal-reply-main > .px-2 {
	vertical-align: middle !important;
}
.modal-title-main .float-right, .modal-reply-main .float-right {
    margin-top: 4px;
}
.add-note-list.dropdown {
    display: block;
    text-align: right;
    margin-bottom: 20px;
}
.add-note-list.dropdown .dropdown-header {
    padding: 0;
        padding-right: 0px;
    display: inline-block;
    padding-right: 30px;
}
.job-detail-modal .modal-header {
    padding-right: 40px;
}
.job-detail-modal .modal-xl {
    max-width: 96%;
}
@media (max-width:991px) {
	.job-detail-modal .modal-body {
	padding: 10px 0px 40px;
}
.modal-title-content span, .modal-reply-text span {
	font-size: 16px;
}
.modal-title-main .sb-avatar, .modal-reply-main .sb-avatar {
	margin-right: 5px;
}
.modal-title-main span time, .modal-reply-main span time {
	font-size: 16px;
}
.modal-title-main .float-right svg, .modal-reply-main .float-right svg {
	width: 24px;
	height: 24px;
	margin-left: 10px;
}
	.modal-image-main .img-thumbnail {
	margin: 10px;
}
.job-detail-modal .close {
	width: 35px;
	height: 35px;
	right: 10px;
	top: 10px;
}
.job-detail-modal .close span {
	font-size: 30px;
	margin-top: -6px;
	margin-left: 0;
}
.job-detail-modal .modal-title p {
    font-size: 18px;
}
.job-detail-modal .modal-title .small {
    font-size: 16px;
}
.add-note-list.dropdown {
	margin-bottom: 10px;
}
.modal-title-main {
	padding: 0px 20px !important;
}
.modal-reply-main {
	padding: 8px 10px !important;
}
.modal-title-main .modal-inner {
	padding: 8px 0;
}
.modal-title-content {
	padding-left: 20px !important;
	padding-right: 20px !important;
}
.modal-reply-main {
	margin-right: 20px;
}
.add-note-list.dropdown .dropdown-header {
	padding-right: 15px;
}
}

@media (max-width:479px) {
	
	.modal-title-main .modal-inner {
	padding: 0;
	border-bottom: 0;
}
	.modal-title-main {
	padding: 8px 10px !important;
	border-bottom: 1px solid #1b67d3;
}
.modal-reply-main {
	margin-right: 0;
}
.modal-reply-text {
	padding: 10px 10px 10px !important;
}
.modal-title-content {
	padding-left: 10px !important;
	padding-right: 10px !important;
}
}