.JobListRow table .list-m-hide-title {
    display: none;
}
@media (max-width:767px) {
	.JobListRow table .list-m-hide-title {
        display: table-cell;
    }
    .JobListRow table tr th {
        padding: 0 10px;
    }
    .JobListRow table .list-m-hide-t {
        display: none;
    }
}