.Welcome-to-WalkThrou {
    /*margin: 0 0 18px;*/
    font-family: KeepCalm;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.34px;
    text-align: center;
    color: #ffffff;
}


.Welcome-to-WalkThrou .welcome-to {
}

.Welcome-to-WalkThrou .walk {
    font-size: 26px;
    letter-spacing: -0.36px;
}

.Welcome-to-WalkThrou .through {
    color: #1a67d3;
}

.Welcome-to-WalkThrou .by {
    font-size: 16px;
    letter-spacing: -0.36px;
}

.Welcome-to-WalkThrou .task {
    letter-spacing: -0.36px;
}
.Welcome-to-WalkThrou .tape {
    color: #1a67d3;
}

.share-container {
    display: inline-block;
}

.The-job-job-name-h {
    width: 293px;
    height: 38px;
    margin: 18px 52px 0 30px;
    font-family: SFProDisplay;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
    text-align: center;
    color: #a0a0a0;
}

.view-register-block {
    width: 375px;
    height: 182px;
    /*margin: 164px 0 0;*/
    padding: 27px 0 37px;
    background-color: rgba(0, 0, 0, 0.5);
}

.view-register-block .share-btn {

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    text-align: center;

    font-family: SFProText, sans-serif;
    margin: 0 28px 31px 33.8px;
    padding: 4px 0 5px;
    width: 100px;
    height: 28px;
    border-radius: 100px;
}

.view-register-block .view-job-btn {
    color: #ffffff;
}
/*a:hover {color: #ffffff;}*/
.view-register-block .register-btn {
    background-color: #5ea514;
    border-color: #5ea514;
}

.learn-about-walkthro {
    width: 375px;
    height: 19px;
    margin: 31px 0 0;
    font-family: SFProDisplay;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.19px;
    text-align: center;
    color: #c8c8c8;
    white-space:nowrap;
}


.post-register {
    width: 375px;
    height: 48px;
    margin: 0 0 9.5px;
    font-family: SFProDisplay;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.19px;
    text-align: center;
    color: #ffffff;
}
