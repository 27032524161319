.add-note-list-buttongroup {
    background-color: #f7f7f7;
    margin-left: 10px;

}

.dropdown-menu {
    border: none;
    background-color: #f7f7f7;
}

.image-modal-main .modal-xl {
    max-width: 96%;
}

.image-modal-main .close span {
    line-height: normal;
    font-size: 30px;
    margin-top: -6px;
    margin-left: 0;
}

.image-modal-main .close {
    width: 35px;
    height: 35px;
    background-color: #1b67d3;
    border-radius: 50%;
    opacity: 1 !important;
    text-shadow: inherit;
    color: #fff !important;
    line-height: 0;
    float: none;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-modal-main .modal-header {
    border-bottom-color: #1b67d3;
}

.image-modal-main .modal-footer {
    border-color: #1b67d3;
}

.image-modal-main .modal-content {
    border-color: #1b67d3;
}

/** Override Carousel next/prev **/
.carousel-control-prev-icon,
.carousel-control-next-icon {
    height: 100px;
    width: 100px;
    outline: black;
    background-size: 100%, 100%;
    border-radius: 50%;
    /*border: 1px solid black;*/
    background-image: none;
}

.carousel-control-next-icon:after
{
    content: '>';
    font-size: 55px;
    color: #1b67d3;
}

.carousel-control-prev-icon:after {
    content: '<';
    font-size: 55px;
    color: #1b67d3;
}

/** Override Carousel indicators **/
.tt__image_note_carousel_main div ol li {
    background-color: #1b67d3;
    /*border-radius: 50%;*/
    /*height: 10px;*/
    /*width: 10px;*/
}
.carousel-caption p {
    color: #5EA514;
    font-size: 26px;
}

.deleteButtonWrap {
    position:relative;
    display:inline-block;
}
@media (max-width: 640px) {
    .image-modal-main {
        padding-right: 0 !important;
    }

    .image-modal-main .modal-header {
        border-bottom-color: #1b67d3;
        padding: 10px;
    }

    .image-modal-main .close {
        width: 30px;
        height: 30px;
    }

    .image-modal-main .close span {
        font-size: 28px;
    }
}