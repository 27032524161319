/**profile***/
.profile-form .form-group input[type="text"] {
	height: 50px;
	border: 1px solid #cbcccd;
	padding: 0 20px;
	color: #9e9e9e;
	font-size: 17px;
	border-radius: 6px;
	box-shadow: 0 1px 3px #a2a2a4;
}
.profile-form .fileUploader .fileContainer .chooseFileButton {
	background-color: #1b67d3;
}
.profile-form .col button.btn {
	background-color: #1b67d3 !important;
	opacity: 1;
	border: 0;
	font-weight: 700;
	font-size: 22px;
	border-radius: 30px;
	padding: 5px 20px 8px;
	line-height: normal;
	width: 220px;
	margin: 30px auto 0;
	cursor: pointer;
	box-shadow: inherit !important;
}
.profile-form {
    padding: 60px 0;
}
.profile-form .fileUploader .fileContainer {
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 1px 3px #a2a2a4;
	border: 1px solid #cbcccd;
	margin-bottom: 20px;
}
.profile-form .col .card {
	border-radius: 5px;
	box-shadow: 0 1px 3px #a2a2a4;
	border: 1px solid #cbcccd;
	width: 100% !important;
}
.profile-form .col .card .card-body {
    padding: 15px;
}
.profile-form .col .card .card-body .card-title {
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0;
    color: #1a67d3;
    margin-bottom: 15px;
}
.profile-form .col .card .card-body p .lead {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}
.profile-form .col .card .card-body p {
    margin-bottom: 8px;
    font-size: 16px;
    color: #000;
}
.profile-form .profile-upload.col .btn {
	margin-left: 20px;
	margin-top: 0;
	font-size: 18px;
	width: auto;
	padding: 5px 30px 8px;
}
.profile-upload > img {
	border-radius: 5px;
	width: 100%;
	max-width: 300px !important;
	height: auto;
}
.profile-upload {
    margin-bottom: 20px;
}

.top-info {
    text-align: center;padding-bottom:10px;
}
@media (max-width:767px) {

/**profile**/
	
	.profile-form-single {
    flex-direction: column;
}
.profile-form-single .col {
    margin: 5px 0;
}
.profile-form .col button.btn {
	font-size: 20px;
	margin: 10px auto 0;
	padding: 8px 20px 11px;
}
	.profile-form {
	padding: 30px 0;
}
.profile-upload > img {
	max-width: 200px !important;
}
}
@media (max-width:480px) {
	
	.profile-upload > img {
    max-width: 180px !important;
    margin: 0 auto;
    display: block;
}
.profile-form .profile-upload.col .btn {
	margin-left: auto;
	margin-top: 15px;
	font-size: 18px;
	margin-right: auto;
	display: block;
}
}