
.loginPage {
    max-width: 485px;
    margin: 0 auto;
    text-align: center;
}

.loginForm form {
    max-width: 100%;
    margin-top: 40px;
}

.Login .create-account {
    white-space: nowrap;
    color: red;
}

.loginForm {
    background-color: #fff;
    border-radius: 10px;
    border: 0;
    text-align: left;
    padding: 40px 40px 80px;
    border: 0px solid #000;
    margin-top: 50px;
}

.loginForm form .form-group label {
    display: none;
}

.loginForm h3 {
    color: #000;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 22px;
}

.loginForm form .form-group input:focus {
    border-color: #1b68d5;
}

.loginForm form .form-group input {
    border: 1px solid #dfdfe0;
    border-radius: 5px;
    height: 53px;
    color: #8c8c8c;
    font-size: 20px;
    padding: 0 30px;
    box-shadow: 0px 2px 5px #b4b4b5;
    line-height: normal;
}

.loginForm form button.btn {
    width: 256px;
    margin: 0 auto;
    background-color: #1b68d5;
    border: 0;
    padding: 11px 10px;
    font-weight: bold;
    cursor: pointer;
    font-size: 22px;
    color: #fff;
    line-height: normal;
    border-radius: 50px;
    margin-top: 15px;
}

.loginForm form small.form-text {
    text-align: center;
    margin-top: 30px;
}

.loginForm form small.form-text span {
    padding: 0;
    color: #000;
    font-size: 14px;
    font-weight: normal;
}

.loginForm form small.form-text a {
    padding: 0;
    color: #1b68d5;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #1b68d5;
    display: inline-block;
    padding-bottom: 5px;
}

.loginForm form span.forgot-password a {
    color: #1b68d5;
    font-weight: bold;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
    display: inline-block;
}

.loginForm form span.forgot-password a {
    color: #1b68d5;
    font-weight: bold;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
    display: inline-block;
}

.loginForm form .form-group {
    margin-bottom: 22px;
}

div#root {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

div#root .App.container {
    background-color: transparent;
}

.signupPage .loginForm form button.btn {
    margin-top: 40px;
}

@media (max-width: 400px) {
    body, html {

        max-width: 100%;
    }

    .loginImage img {
        width: 100%;
    }

    .loginForm form button.btn {
        width: 100%;
    }

    div#root {
        display: block;
    }

    .loginPage {
        padding: 30px 0;
    }

    .loginForm {
        margin-top: 30px;
    }

    .loginForm form small.form-text span {
        white-space: normal;
    }

    .loginForm {
        padding: 30px 20px;
    }

    .loginForm form .form-group input {
        padding: 0 20px;
    }
}