
div#root {
	display: block;
}
.upper-top-bar {
	background-color: #f7f7f7;
	margin-bottom: 40px;
}
.container, .container-sm, .container-md, .container-lg, .container-xl, .container-fluid {
	max-width: 1025px !important;
}
.accordion {
	background-color: #f7f7f7;
	box-shadow: 0 0 5px #ececec;
}
.accordion .card {
	border-radius: 0 !important;
	border: 0 !important;
	box-shadow: inherit !important;
	background-color: transparent !important;
}
.accordion .card .card-header > .card-header {
	padding: 0;
	margin: 0 !important;
	border: 0;
	display: flex;
	width: 100%;
	padding-right: 20px;
}
.accordion .card .card-header p {
    margin: 0;
}
.accordion .card .card-header:hover p {
 color:#1a67d3;
}
.accordion .card:last-child .card-header {border-bottom:0; }
.accordion .card .card-header {
	padding: 10px 0;
	margin: 0 25px !important;
	border: 0;
	background-color: transparent;
	line-height: normal;
	border-bottom: 1px solid #c0d4ef;
	color: #191919;
	font-size: 18px;
	font-weight: 300;
	display: flex;
	align-items: center;
}
.accordion .selected-taskview {
	background-color: #1a67d3;
}
.accordion .collapse {
	padding: 0;
}
.accordion .collapse .card-body {
    padding: 10px;
    flex: inherit;
    min-height: inherit;
}
.accordion .collapse .card-body img {
    width:100%;
}
.accordion .active.card > .card-header::before {
		transform: rotate(-45deg);
}

.accordion .card > .card-header::before {
	content: "";
	position: absolute;
	right: 10px;
	border-left: 2px solid #000000;
	border-bottom: 2px solid #000000;
	width: 9px;
	height: 9px;
	transform: rotate(-135deg);
	top: 25px;
	transition: all 0.3s ease-in-out;
}
.accordion .card > .card-header {
	position: relative;
}
.right-side-bar img {
	width: 100% !important;
	padding: 0;
	border-radius: 0;
	border: 0;
}
.upper-top-bar {
	background-color: #f7f7f7;
	margin-bottom: 20px;
	width: 100%;
	position: relative;
	padding: 15px 0 25px;

}
.upper-top-bar::before {
	content: "";
	background-color: #1a67d3;
	height: 1px;
	width: 70%;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	margin: 0 auto;
}
.upper-top-bar .col {
    color: #1a1a1a;
    font-weight: 700;
    font-size: 25px;
    line-height: normal;
}
.upper-top-bar .col time {
	color: #1a67d3;
}
.upper-top-bar .col .created-by {
	color: #1a67d3;
	font-size: 14px;
}
.upper-top-bar .col svg {
    width: 30px;
    height: 30px;
}
.acc-title {
    background-color: #1a67d3;
    text-align: center;
    padding: 5px 10px 6px;
    color: #fff;
    position: relative;
    z-index: 9;
}
.acc-title h4 {
    margin: 0;
    text-transform: capitalize;
    font-weight: normal;
    font-size: 24px;
}
.right-side-bar .font-weight-bold {
    display: none;
}

.address-upper .col br {
    display: none;
}
.address-upper .col span {color:#1a67d3;}
.address-upper .col svg {display:none; }
.address-upper .col {
    color: #000;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
}
.address-upper {
	padding: 10px 0 10px;
	display: inline-block;
	width: 100%;
	text-align: center;
}
.address-upper .col .card > .card-header {
	padding: 0;
	margin: 0 !important;
}
.accordion .card .card-header .rounded-circle {
    height: 40px;
    width: 40px;
    margin-right: 10px;
}
.upper-top-bar .col {
	text-align: center;
}
.address-upper .card > .card-header::before {
    display: none;
}
.address-upper .card .card-header > .card-header {
    padding-right: 0;
    justify-content: center;
}
.address-upper .col .accordion {
    box-shadow: inherit;
    background-color: transparent;
}
.join-rect {
	width: 100%;
	height: 67px;
	margin: 17px 0 0;
	padding: 0px 14px 3px 212px;
	opacity: 0.5;
	background-color: #67952e;
	cursor: pointer;
}
.Tap-To-Join-Job {
	margin: 5px 10px 1px 43px;
	font-family: SFProDisplay;
	font-size: 24px;
	font-weight: bolder;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fff;
}
.This-will-place-the {
	margin: 1px 12px 0 0;
	font-family: SFProDisplay;
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fff;
}
img.iconshandshake {
	width: 85px;
	height: 83px;
	margin: 10px 10px 0px 10px;
	align-content: center;
	/*object-fit: contain;*/
	/*background-color: #5ea514;*/
	background-color: #ffffff;
}

div.tt-share-icon {
	float: left;
	cursor: pointer;
	z-index: 10;
	position: relative;
}
div.tt-map-icon {
	float: right;
	cursor: pointer;
	z-index: 10;
	position: relative;
}

@media (max-width:767px) {
	
	.upper-top-bar .col {
		font-size: 20px;
	}
	.upper-top-bar .col time {
		font-size: 18px;
	}
	.upper-top-bar {
		margin-bottom: 10px;
		padding: 10px 0 15px;
	}
	.address-upper {
		padding: 10px 0 0px;
		width: 100%;
	}
	.address-upper .col {
		font-size: 18px;
	}
	.acc-title h4 {
		font-size: 20px;
	}
	.right-side-bar {
		margin-top: 20px;
	}
	.upper-top-bar .col img.sb-avatar__image {
		width: 45px !important;
		height: 45px !important;
	}
}
@media (max-width:479px) {
		.accordion .card .card-header {
		font-size: 16px;
	}
}