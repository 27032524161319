
.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander {
	display: flex;
	flex-wrap: wrap;
	border: 1px solid #d5d5d5;
	box-shadow: 0 0 6px #00000026;
}
.lander .lander-left {
	width: 50%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	background-color: #1a67d3bd;
	position: relative; 	z-index:1;
}
.lander .lander-left::before {
	content: "";
	background-color: #1a67d3;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0.8;
	z-index: -1;
}
.lander .lander-right {
	width: 50%;
	padding: 110px 30px 100px;
	position: relative;
}
.Available-content {
	position: absolute;
	right: 0;
	top: 0;
	background-color: #1a67d3;
	color: #fff;
	border-radius: 0 0 0 30px;
	width: 186px;
	padding: 2px 10px 3px 25px;
	line-height: normal;
	font-size: 15px;
	display: flex;
}
.Available-icons {
    display: inline-block;
    margin-left: auto;
}
.lander .lander-left h1 {
    color: #fff;
    font-size: 50px;
}
.lander .lander-right h1 {
	color: #1a67d3;
	font-weight: normal;
	font-size: 30px;
	margin: 0;
	text-align: center;
}
.lander .lander-right ul {
	padding: 8px 25px;
	background-color: #1a67d3;
	list-style: none;
	border-radius: 60px;
	margin: 40px 0 20px;
	display: inline-block;
}
.lander .lander-right ul li {
	color: #fff;
	display: inline-block;
	font-size: 20px;
	position: relative;
	margin-left: 30px;
}
.lander .lander-right ul li:first-child {
	margin-left: 0;
}
.lander .lander-right ul li::before {
	content: "";
	background-color: #fff;
	width: 8px;
	height: 8px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 8px;
	vertical-align: middle;
}
.lander .lander-right h1 span {
    color: #000;
}
.navbar .justify-content-end .navbar-nav::before {
	content: "";
	width: 1px;
	height: 20px;
	position: absolute;
	display: none;
	left: 0;
	right: -45px;
	margin: auto;
	background-color: #fff;
	top: 0;
	bottom: 0;
}
.Available-icons a {
    text-decoration: none !important;
}
.navbar .justify-content-end .navbar-nav {
    background-color: #000;
}
/* .navbar .justify-content-end .navbar-nav a.nav-link {
    color: #fff !important;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    padding: 15px 20px;
} */
.navbar .justify-content-end .navbar-nav a.nav-link {
    color: #1a67d3 !important;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    padding: 15px 20px;
}

.Home .container {
    max-width: 1056px !important;
}
.lander .lander-right p {
    font-size: 15px;
    line-height: 30px;
    color: #000;
}

.lander .lander-right ul.bottom-icons li::before {
    display: none;
}
.lander .lander-right ul.bottom-icons {
	padding: 0;
	background-color: transparent;
	display: block;
	text-align: center;
	border-radius: 0;
	margin: 30px 0 0 0;
}
.lander .lander-right ul.bottom-icons .icon-bg {
    height: 36px;
    width: 36px;
    display: inline-block;
    background-color: #1a67d3;
    border-radius: 50%;
    margin-bottom: 10px;
}
.lander .lander-right ul.bottom-icons li p {
    margin-bottom: 0;
    line-height: normal;
}
.lander .lander-right ul.bottom-icons li {
    width: auto;
    margin: 0 30px 20px;
}
.art-img {
	margin-top: 30px;
	text-align: center;
}
.lander .lander-right .Available-content ul li::before {
    display: none;
}
.lander .lander-right .Available-content ul {
	border-radius: 0;
	padding: 0;
	margin: 0;
}

@media (max-width:1030px) {
	.lander .lander-left h1 {
    font-size: 40px;
}
	.lander .lander-right ul li {
	margin-left: 20px;
}

}
@media (max-width:991px) {
	.lander .lander-right ul li {
	font-size: 18px;
}
.lander .lander-right ul.bottom-icons li {
	margin: 0 16px 15px;
}
.art-img img {
    width: 100%;
}
.lander .lander-right {
	padding: 100px 30px 100px;
}
.navbar .justify-content-end .navbar-nav a.nav-link {
	font-size: 15px;
	padding: 12px 10px;
}
}

@media (max-width:767px) {
	
	.lander .lander-left {
	width: 100%;
	height: 300px;
}
.lander .lander-right {
	padding: 80px 20px 50px;
	width: 100%;
	text-align: center;
}
.art-img img {
	max-width: 357px;
}
	.navbar .justify-content-end .navbar-nav::before {
	display: none;
}
.navbar .justify-content-end .navbar-nav {
    flex-direction: column;
    display: block;
}
.navbar .justify-content-end .navbar-nav a.nav-link {
    width: auto;
    display: inline-block !important;
    max-width: inherit !important;
}
.navbar .justify-content-end .navbar-nav {
    flex-direction: column;
    display: block;
    text-align: center;
}
}

@media (max-width:479px) {
.lander .lander-right ul.bottom-icons li {
	margin: 0 10px 15px;
	display: inline-block;
}
.lander .lander-right ul {
	margin: 30px 0 20px;
	padding: 10px 10px 12px;
	line-height: 10px;
}
.lander .lander-right ul li {
	font-size: 13px;
	margin-left: 5px;
}
.lander .lander-right ul li::before {
	width: 6px;
	height: 6px;
	margin-right: 5px;
}

.lander .lander-right {
	padding: 60px 10px 50px;
}
}
@media (max-width:639px) {

.Available-content {
display:none;
}}